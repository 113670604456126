<template>
    <div id="app">
        <router-view/>
    </div>
</template>

<script>
    export default {
        name: 'app'
    }
</script>

<style>
    html, body, #app {
        font-family: 'Avenir', Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        width: 100%;
        height: 100%;
    }

    body {
        margin: 0;
        padding: 0;
    }
</style>

<!--https://www.jq22.com/demo/jquerybootstrapydmoban202105062343/index.html-->
