import {createRouter, createWebHashHistory} from 'vue-router'

const routes = [
    {
        path: '/',
        component: () => import('../views/index'),
        meta: {title: '首页'}
    },
    {
        path: '/about',
        component: () => import('../views/about'),
        meta: {title: '关于'}
    },
    {
        path: '/contact_us',
        component: () => import('../views/contact_us'),
        meta: {title: '联系我们'}
    }
];

const router = createRouter({
    history: createWebHashHistory(),
    routes
});

export default router
